"use client";

import { ThemeProvider } from '@mui/material/styles';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter';
import { CssBaseline } from '@mui/material';
import theme from '@/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
export default function ClientProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="ClientProvider" data-sentry-source-file="ClientProvider.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="ClientProvider.tsx">
        <CssBaseline enableColorScheme data-sentry-element="CssBaseline" data-sentry-source-file="ClientProvider.tsx" />
        <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="ClientProvider.tsx">
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </AppRouterCacheProvider>;
}