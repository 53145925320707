import QRCode from 'qrcode';
import { customAlphabet } from 'nanoid';
import { type GrowBag, type Product, type Specimen } from '@/models/types';
import { type Schema } from '@/amplify/data/resource';
import { generateClient } from 'aws-amplify/data';

export type TrackableItem = GrowBag | Product | Specimen;

// Create a Code128-compatible ID generator
// Using only numbers and uppercase letters for maximum compatibility
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10);

interface TrackingInfo {
  trackingId: string;
  qrCode: string;
}

export async function generateTrackingInfo(type: string, prefix: string = 'GB'): Promise<TrackingInfo> {
  // Generate tracking ID (e.g., GB0123456789)
  const trackingId = `${prefix}${nanoid()}`;
  
  // Generate QR code with full tracking URL including type
  const trackingUrl = `${process.env['NEXT_PUBLIC_APP_URL']}/track/${type}/${trackingId}`;
  const qrCode = await QRCode.toDataURL(trackingUrl, {
    errorCorrectionLevel: 'H',
    margin: 1,
    width: 300,
  });

  return {
    trackingId,
    qrCode,
  };
}

export function validateTrackingId(trackingId: string): boolean {
  // Validate format: 2 letters followed by 10 alphanumeric characters
  const pattern = /^[A-Z]{2}[0-9A-Z]{10}$/;
  return pattern.test(trackingId);
}

export async function fetchTrackableItem(id: string, type: 'growbag' | 'product' | 'specimen') {
  const client = generateClient<Schema>();
  
  try {
    switch (type) {
      case 'growbag':
        return await client.models.GrowBag.get({ id });
      case 'product':
        return await client.models.Product.get({ id });
      case 'specimen':
        return await client.models.Specimen.get({ id });
      default:
        throw new Error('Invalid tracking type');
    }
  } catch (error) {
    console.error('Error fetching trackable item:', error);
    throw error;
  }
}

export function getTrackingTitle(item: TrackableItem): string {
  if ('trackingId' in item) {
    return `Grow Bag ${item.trackingId}`;
  }
  if ('sku' in item) {
    return `Product ${item.sku || item.id}`;
  }
  if ('identificationCode' in item) {
    return `Specimen ${item.identificationCode}`;
  }
  return 'Unknown Item';
}

export function getTrackingMetadata(item: TrackableItem) {
  const base = {
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    status: item.status,
  };

  if ('trackingId' in item) {
    return {
      ...base,
      type: 'growbag',
      identifier: item.trackingId,
      substrateType: item.substrateType,
    };
  }

  if ('sku' in item) {
    return {
      ...base,
      type: 'product',
      identifier: item.sku || item.id,
      category: item.category,
    };
  }

  if ('identificationCode' in item) {
    return {
      ...base,
      type: 'specimen',
      identifier: item.identificationCode,
      source: item.source,
    };
  }

  return base;
}
