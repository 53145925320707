import(/* webpackMode: "eager", webpackExports: ["WaitlistFormUI"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/components/waitlist-form/WaitlistFormUI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Paper/Paper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src2050438168/src/amplify-nextjs-mushmyco-app/node_modules/next/dist/client/app-dir/link.js");
