'use client';

import { useEffect, useState, useRef, useCallback } from 'react';
import { Html5QrcodeScanner, Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Stack, Switch, FormControlLabel, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadIcon from '@mui/icons-material/Upload';
interface QRCodeScannerProps {
  onClose: () => void;
  onScan: (data: string | string[]) => void;
  batchMode?: boolean;
  handleScanComplete?: (data: string | string[]) => void;
}
export default function QRCodeScanner({
  onClose,
  onScan,
  batchMode = false,
  handleScanComplete
}: QRCodeScannerProps) {
  const [isScanning, setIsScanning] = useState(false);
  const [useCamera, setUseCamera] = useState(true);
  const [scannedItems, setScannedItems] = useState<string[]>([]);
  const [continuousScan, setContinuousScan] = useState(batchMode);
  const scannerRef = useRef<Html5QrcodeScanner | null>(null);
  const elementId = 'qr-reader';
  const handleClose = useCallback(() => {
    if (scannerRef.current && isScanning) {
      try {
        scannerRef.current.clear();
      } catch (error) {
        console.warn('Error clearing scanner:', error);
      }
    }
    setIsScanning(false);
    onClose();
  }, [isScanning, onClose]);
  const handleSubmitBatch = () => {
    if (scannedItems.length > 0) {
      onScan(scannedItems);
      handleClose();
    }
  };
  const handleScan = useCallback((decodedText: string) => {
    if (continuousScan) {
      if (!scannedItems.includes(decodedText)) {
        setScannedItems(prev => [...prev, decodedText]);
      }
    } else {
      onScan(decodedText);
      handleClose();
    }
    if (handleScanComplete) {
      handleScanComplete(decodedText);
    }
  }, [continuousScan, scannedItems, onScan, handleClose, handleScanComplete]);
  useEffect(() => {
    const initializeScanner = setTimeout(() => {
      if (!scannerRef.current && document.getElementById(elementId)) {
        scannerRef.current = new Html5QrcodeScanner(elementId, {
          fps: 10,
          qrbox: {
            width: 250,
            height: 250
          },
          rememberLastUsedCamera: true,
          formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE, Html5QrcodeSupportedFormats.DATA_MATRIX, Html5QrcodeSupportedFormats.EAN_13, Html5QrcodeSupportedFormats.MAXICODE, Html5QrcodeSupportedFormats.AZTEC, Html5QrcodeSupportedFormats.CODE_128],
          experimentalFeatures: {
            useBarCodeDetectorIfSupported: true
          }
        }, /* verbose= */false);
        scannerRef.current.render(decodedText => {
          handleScan(decodedText);
        }, error => {
          console.warn(`QR Code scanning error: ${error}`);
        });
        setIsScanning(true);
      }
    }, 100);
    return () => {
      clearTimeout(initializeScanner);
      if (scannerRef.current && isScanning) {
        try {
          scannerRef.current.clear();
        } catch (error) {
          console.warn('Error clearing scanner:', error);
        }
      }
    };
  }, [handleScan, isScanning, onScan]);
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    try {
      const html5Qrcode = new Html5Qrcode(elementId);
      const imageFile = file;
      const decodedText = await html5Qrcode.scanFile(imageFile, true);
      await html5Qrcode.clear();
      handleScan(decodedText);
    } catch (error) {
      console.error("Error scanning file:", error);
    }
  };
  const removeScannedItem = (item: string) => {
    setScannedItems(prev => prev.filter(i => i !== item));
  };
  const toggleScanMethod = () => {
    if (scannerRef.current && isScanning) {
      try {
        scannerRef.current.clear();
      } catch (error) {
        console.warn('Error clearing scanner:', error);
      }
    }
    setUseCamera(!useCamera);
  };
  return <Dialog open onClose={handleClose} maxWidth="sm" fullWidth PaperProps={{
    sx: {
      bgcolor: 'background.paper',
      minHeight: '400px'
    }
  }} data-sentry-element="Dialog" data-sentry-component="QRCodeScanner" data-sentry-source-file="QRCodeScanner.tsx">
      <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="QRCodeScanner.tsx">
        <Stack direction="row" justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="QRCodeScanner.tsx">
          Scan QR Code
          <Stack direction="row" spacing={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="QRCodeScanner.tsx">
            <IconButton onClick={toggleScanMethod} data-sentry-element="IconButton" data-sentry-source-file="QRCodeScanner.tsx">
              {useCamera ? <CameraAltIcon /> : <UploadIcon />}
            </IconButton>
            {batchMode && <FormControlLabel control={<Switch checked={continuousScan} onChange={e => setContinuousScan(e.target.checked)} />} label="Continuous Scan" />}
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="QRCodeScanner.tsx">
        <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="QRCodeScanner.tsx">
          {useCamera ? <Box id={elementId} sx={{
          '& video': {
            borderRadius: 1
          },
          '& img': {
            display: 'none'
          }
        }} /> : <Button variant="outlined" component="label" fullWidth startIcon={<UploadIcon />}>
              Upload QR Code Image
              <input type="file" hidden accept="image/*" onChange={handleFileUpload} />
            </Button>}

          {continuousScan && <>
              <Typography variant="subtitle2">
                Scanned Items: {scannedItems.length}
              </Typography>
              <List dense>
                {scannedItems.map((item, index) => <ListItem key={index} secondaryAction={<IconButton edge="end" onClick={() => removeScannedItem(item)}>
                        <DeleteIcon />
                      </IconButton>}>
                    <ListItemText primary={item} />
                  </ListItem>)}
              </List>
            </>}
        </Stack>
      </DialogContent>
      <DialogActions data-sentry-element="DialogActions" data-sentry-source-file="QRCodeScanner.tsx">
        <Button onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="QRCodeScanner.tsx">Cancel</Button>
        {continuousScan && <Button onClick={handleSubmitBatch} variant="contained" disabled={scannedItems.length === 0}>
            Submit {scannedItems.length} Items
          </Button>}
      </DialogActions>
    </Dialog>;
}