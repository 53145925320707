'use client';

import { useState, useEffect } from 'react';
import Image, { type ImageProps } from 'next/image';
import { getSignedUrl } from '@/utils/storage';
interface SignedImageProps extends Omit<ImageProps, 'src'> {
  storagePath: string;
  initialUrl?: string;
  expiresAt?: string;
}
export default function SignedImage({
  storagePath,
  initialUrl,
  expiresAt,
  alt,
  ...props
}: SignedImageProps) {
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(!initialUrl);
  useEffect(() => {
    const refreshUrl = async () => {
      if (!url || expiresAt && new Date(expiresAt) <= new Date()) {
        setIsLoading(true);
        try {
          const newUrl = await getSignedUrl(storagePath);
          setUrl(newUrl);
        } catch (error) {
          console.error('Error refreshing image URL:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    refreshUrl();
  }, [storagePath, url, expiresAt]);
  if (isLoading || !url) {
    // Return a placeholder or loading state
    return <div className="image-placeholder" {...props} />;
  }
  return <Image src={url} alt={alt} {...props} data-sentry-element="Image" data-sentry-component="SignedImage" data-sentry-source-file="SignedImage.tsx" />;
}