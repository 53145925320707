'use client';

import { useState } from 'react';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import type { Species } from '@/models/types';
interface SpeciesFormProps {
  initialData?: Partial<Species>;
  onSubmit: (data: Partial<Species>) => Promise<void>;
  isSubmitting?: boolean;
}
export default function SpeciesForm({
  initialData,
  onSubmit,
  isSubmitting = false
}: SpeciesFormProps) {
  const [formData, setFormData] = useState<Partial<Species>>(initialData || {
    commonName: '',
    scientificName: '',
    phenotypicDetails: '',
    edibility: 'UNKNOWN',
    medicinalProperties: ''
  });
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData);
  };
  return <Box component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-component="SpeciesForm" data-sentry-source-file="SpeciesForm.tsx">
      <Grid container spacing={2} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <TextField fullWidth required label="Common Name" value={formData.commonName || ''} onChange={e => setFormData(prev => ({
          ...prev,
          commonName: e.target.value
        }))} data-sentry-element="TextField" data-sentry-source-file="SpeciesForm.tsx" />
        </Grid>
        
        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <TextField fullWidth label="Scientific Name" value={formData.scientificName || ''} onChange={e => setFormData(prev => ({
          ...prev,
          scientificName: e.target.value
        }))} data-sentry-element="TextField" data-sentry-source-file="SpeciesForm.tsx" />
        </Grid>

        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <FormControl fullWidth data-sentry-element="FormControl" data-sentry-source-file="SpeciesForm.tsx">
            <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="SpeciesForm.tsx">Edibility</InputLabel>
            <Select value={formData.edibility || 'UNKNOWN'} label="Edibility" onChange={e => setFormData(prev => ({
            ...prev,
            edibility: e.target.value as 'EDIBLE' | 'INEDIBLE' | 'POISONOUS' | 'UNKNOWN'
          }))} data-sentry-element="Select" data-sentry-source-file="SpeciesForm.tsx">
              <MenuItem value="EDIBLE" data-sentry-element="MenuItem" data-sentry-source-file="SpeciesForm.tsx">Edible</MenuItem>
              <MenuItem value="INEDIBLE" data-sentry-element="MenuItem" data-sentry-source-file="SpeciesForm.tsx">Inedible</MenuItem>
              <MenuItem value="POISONOUS" data-sentry-element="MenuItem" data-sentry-source-file="SpeciesForm.tsx">Poisonous</MenuItem>
              <MenuItem value="UNKNOWN" data-sentry-element="MenuItem" data-sentry-source-file="SpeciesForm.tsx">Unknown</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <TextField fullWidth multiline rows={3} label="Phenotypic Details" value={formData.phenotypicDetails || ''} onChange={e => setFormData(prev => ({
          ...prev,
          phenotypicDetails: e.target.value
        }))} data-sentry-element="TextField" data-sentry-source-file="SpeciesForm.tsx" />
        </Grid>

        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <TextField fullWidth multiline rows={3} label="Medicinal Properties" value={formData.medicinalProperties || ''} onChange={e => setFormData(prev => ({
          ...prev,
          medicinalProperties: e.target.value
        }))} data-sentry-element="TextField" data-sentry-source-file="SpeciesForm.tsx" />
        </Grid>

        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="SpeciesForm.tsx">
          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="SpeciesForm.tsx">
            {isSubmitting ? 'Saving...' : 'Save Species'}
          </Button>
        </Grid>
      </Grid>
    </Box>;
}