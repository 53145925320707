export function formatUserId(userId: string): string {
  // If it's a UUID, show only first 8 chars
  if (userId.includes('-')) {
    return userId.split('-')[0];
  }
  // For other formats, show first 8 chars
  return userId.slice(0, 8);
}

export function getUserDisplayName(displayName?: string, username?: string, email?: string): string {
  if (displayName) {
    return displayName;
  }

  if (email) {
    return email.split('@')[0];
  }

  if (username) {
    return username;
  }

  return 'Guest User';
}

export function getAvatarUrl(avatar?: string): string | undefined {
  if (avatar?.startsWith('uploads/')) {
    return `https://${process.env['NEXT_PUBLIC_STORAGE_BUCKET']}.s3.amazonaws.com/${avatar}`;
  }

  if (avatar) {
    return avatar;
  }

  return undefined;
}
