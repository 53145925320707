'use client';

import { useState, useEffect, type ChangeEvent } from 'react';
import styles from './StrainForm.module.css';
import { Box, Paper, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Grid, LinearProgress } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { dataClient } from '@/components/ConfigureAmplify';
import { useNotifications } from '@/hooks/useNotifications';
import { uploadFile, STORAGE_PATHS } from '@/utils/storage';
import type { Organization, Species } from '@/models/types';
type StrainStatus = 'ACTIVE' | 'ARCHIVED' | 'DISCONTINUED';
interface StrainFormData {
  name: string;
  description: string;
  origin: string;
  isolationDate: Date | null;
  geneticLineage: string;
  characteristics: string;
  growthParameters: string;
  status: StrainStatus;
  notes: string;
  speciesId: string;
  organizationId: string;
}
interface StrainFormProps {
  initialData?: {
    id?: string;
    name?: string;
    description?: string;
    origin?: string;
    isolationDate?: string;
    geneticLineage?: string;
    characteristics?: string;
    growthParameters?: string;
    status?: StrainStatus;
    notes?: string;
    speciesId?: string;
    organizationId?: string;
    images?: string[];
  };
  mode: 'create' | 'edit';
}
export default function StrainForm({
  initialData,
  mode
}: StrainFormProps) {
  const [formData, setFormData] = useState<StrainFormData>({
    name: initialData?.name || '',
    description: initialData?.description || '',
    origin: initialData?.origin || '',
    isolationDate: initialData?.isolationDate ? new Date(initialData.isolationDate) : null,
    geneticLineage: initialData?.geneticLineage || '',
    characteristics: initialData?.characteristics || '',
    growthParameters: initialData?.growthParameters || '',
    status: initialData?.status || 'ACTIVE',
    notes: initialData?.notes || '',
    speciesId: initialData?.speciesId || '',
    organizationId: initialData?.organizationId || ''
  });
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState<Species[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const router = useRouter();
  const {
    showNotification
  } = useNotifications();
  useEffect(() => {
    loadOptions();
  }, []);
  const loadOptions = async () => {
    try {
      const [speciesRes, orgsRes] = await Promise.all([dataClient.models.Species.list(), dataClient.models.Organization.list()]);
      setSpecies(speciesRes.data || []);
      setOrganizations(orgsRes.data || []);
    } catch (error) {
      console.error('Error loading options:', error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Upload images if any
      const imageUrls = await Promise.all(imageFiles.map(async file => {
        const result = await uploadFile(file, STORAGE_PATHS.UPLOADS.STRAINS(file.name), {
          maxSize: 5 * 1024 * 1024,
          validateFn: file => file.type.startsWith('image/'),
          onProgress: progress => {
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: progress
            }));
          }
        });
        return result.url;
      }));
      const strainData = {
        ...formData,
        isolationDate: formData.isolationDate?.toISOString(),
        images: imageUrls,
        status: formData.status as StrainStatus
      };
      const result = mode === 'create' ? await dataClient.models.Strain.create({
        name: strainData.name,
        description: strainData.description || null,
        origin: strainData.origin || null,
        isolationDate: strainData.isolationDate || null,
        geneticLineage: strainData.geneticLineage || null,
        characteristics: strainData.characteristics || null,
        growthParameters: strainData.growthParameters || null,
        status: strainData.status,
        notes: strainData.notes || null,
        speciesId: strainData.speciesId,
        organizationId: strainData.organizationId,
        images: strainData.images
      }) : await dataClient.models.Strain.update({
        id: initialData?.id as string,
        ...strainData
      });
      showNotification(`Strain ${mode === 'create' ? 'created' : 'updated'} successfully!`, {
        variant: 'success'
      });
      if (!result.data) {
        router.push('/catalog/strains');
        return;
      }
      router.push(`/catalog/strains/${result.data.id}`);
    } catch (error) {
      console.error('Error saving strain:', error);
      showNotification('Failed to save strain', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  return <Paper className={styles['formContainer']} data-sentry-element="Paper" data-sentry-component="StrainForm" data-sentry-source-file="StrainForm.tsx">
      <Typography variant="h5" gutterBottom data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
        {mode === 'create' ? 'Create New Strain' : 'Edit Strain'}
      </Typography>

      <Box component="form" onSubmit={handleSubmit} data-sentry-element="Box" data-sentry-source-file="StrainForm.tsx">
        <Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
          {/* Basic Information */}
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <Typography variant="subtitle1" color="text.secondary" gutterBottom data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
              Basic Information
            </Typography>
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth required label="Strain Name" value={formData.name} onChange={e => setFormData(prev => ({
            ...prev,
            name: e.target.value
          }))} data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth multiline rows={3} label="Description" value={formData.description} onChange={e => setFormData(prev => ({
            ...prev,
            description: e.target.value
          }))} data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          {/* Relationships */}
          <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <FormControl fullWidth required data-sentry-element="FormControl" data-sentry-source-file="StrainForm.tsx">
              <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="StrainForm.tsx">Species</InputLabel>
              <Select value={formData.speciesId} label="Species" onChange={e => setFormData(prev => ({
              ...prev,
              speciesId: e.target.value
            }))} data-sentry-element="Select" data-sentry-source-file="StrainForm.tsx">
                {species.map((s: Partial<Species>) => <MenuItem key={s.id} value={s.id}>
                    {s.commonName || s.scientificName}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <FormControl fullWidth required data-sentry-element="FormControl" data-sentry-source-file="StrainForm.tsx">
              <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="StrainForm.tsx">Organization</InputLabel>
              <Select value={formData.organizationId} label="Organization" onChange={e => setFormData(prev => ({
              ...prev,
              organizationId: e.target.value
            }))} data-sentry-element="Select" data-sentry-source-file="StrainForm.tsx">
                {organizations.map((org: Partial<Organization>) => <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          {/* Origin and Lineage */}
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{
            mt: 2
          }} data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
              Origin & Lineage
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth label="Origin" value={formData.origin} onChange={e => setFormData(prev => ({
            ...prev,
            origin: e.target.value
          }))} placeholder="e.g., Wild collected, Lab isolated" data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <DatePicker label="Isolation Date" value={formData.isolationDate} onChange={date => setFormData(prev => ({
            ...prev,
            isolationDate: date
          }))} slotProps={{
            textField: {
              fullWidth: true
            }
          }} data-sentry-element="DatePicker" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth label="Genetic Lineage" value={formData.geneticLineage} onChange={e => setFormData(prev => ({
            ...prev,
            geneticLineage: e.target.value
          }))} placeholder="e.g., Parent strains, genetic history" data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          {/* Characteristics and Parameters */}
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{
            mt: 2
          }} data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
              Characteristics & Growth Parameters
            </Typography>
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth multiline rows={3} label="Characteristics" value={formData.characteristics} onChange={e => setFormData(prev => ({
            ...prev,
            characteristics: e.target.value
          }))} placeholder="Physical characteristics, behavior patterns, etc." data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth multiline rows={3} label="Growth Parameters" value={formData.growthParameters} onChange={e => setFormData(prev => ({
            ...prev,
            growthParameters: e.target.value
          }))} placeholder="Temperature ranges, humidity requirements, substrate preferences, etc." data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          {/* Status and Notes */}
          <Grid item xs={12} md={6} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <FormControl fullWidth required data-sentry-element="FormControl" data-sentry-source-file="StrainForm.tsx">
              <InputLabel data-sentry-element="InputLabel" data-sentry-source-file="StrainForm.tsx">Status</InputLabel>
              <Select value={formData.status} label="Status" onChange={e => setFormData(prev => ({
              ...prev,
              status: e.target.value as StrainStatus
            }))} data-sentry-element="Select" data-sentry-source-file="StrainForm.tsx">
                <MenuItem value="ACTIVE" data-sentry-element="MenuItem" data-sentry-source-file="StrainForm.tsx">Active</MenuItem>
                <MenuItem value="ARCHIVED" data-sentry-element="MenuItem" data-sentry-source-file="StrainForm.tsx">Archived</MenuItem>
                <MenuItem value="DISCONTINUED" data-sentry-element="MenuItem" data-sentry-source-file="StrainForm.tsx">Discontinued</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <TextField fullWidth multiline rows={3} label="Additional Notes" value={formData.notes} onChange={e => setFormData(prev => ({
            ...prev,
            notes: e.target.value
          }))} data-sentry-element="TextField" data-sentry-source-file="StrainForm.tsx" />
          </Grid>

          {/* Image Upload */}
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <Typography variant="subtitle1" color="text.secondary" className={styles['sectionSpacing']} data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
              Images
            </Typography>
            <Box className={styles['fileUploadSection']} data-sentry-element="Box" data-sentry-source-file="StrainForm.tsx">
              <input accept="image/*" className={styles['fileInput']} id="strain-images" multiple type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                setImageFiles(Array.from(e.target.files));
              }
            }} />
              <label htmlFor="strain-images">
                <Button variant="outlined" component="span" startIcon={<PhotoCamera />} data-sentry-element="Button" data-sentry-source-file="StrainForm.tsx">
                  Upload Images
                </Button>
              </label>
              <Typography variant="body2" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="StrainForm.tsx">
                {imageFiles.length ? `${imageFiles.length} files selected` : 'No files selected'}
              </Typography>
              {imageFiles.length > 0 && <Box sx={{
              width: '100%',
              mt: 2
            }}>
                  {imageFiles.map(file => <Box key={file.name} sx={{
                mb: 1
              }}>
                      <Typography variant="caption">{file.name}</Typography>
                      {uploadProgress[file.name] !== undefined && <LinearProgress variant="determinate" value={uploadProgress[file.name]} sx={{
                  mt: 0.5
                }} />}
                    </Box>)}
                </Box>}
            </Box>
          </Grid>

          {/* Submit Buttons */}
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="StrainForm.tsx">
            <Box className={styles['buttonGroup']} data-sentry-element="Box" data-sentry-source-file="StrainForm.tsx">
              <Button variant="outlined" onClick={() => router.back()} disabled={loading} data-sentry-element="Button" data-sentry-source-file="StrainForm.tsx">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={loading || !formData.name || !formData.speciesId || !formData.organizationId} data-sentry-element="Button" data-sentry-source-file="StrainForm.tsx">
                {loading ? 'Saving...' : mode === 'create' ? 'Create Strain' : 'Save Changes'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>;
}