'use client';

import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import type { VariantType } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
const NotificationContext = createContext<{
  showNotification: (message: string, variant?: VariantType) => void;
} | undefined>(undefined);
function NotificationProviderInner({
  children
}: {
  children: ReactNode;
}) {
  const {
    enqueueSnackbar,
    closeSnackbar
  } = useSnackbar();
  const showNotification = (message: string, variant: VariantType = 'default') => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 4000,
      action: key => <IconButton size="small" onClick={() => closeSnackbar(key)} sx={{
        color: 'white'
      }}>
          <CloseIcon />
        </IconButton>
    });
  };
  return <NotificationContext.Provider value={{
    showNotification
  }} data-sentry-element="unknown" data-sentry-component="NotificationProviderInner" data-sentry-source-file="NotificationContext.tsx">
      {children}
    </NotificationContext.Provider>;
}
export function NotificationProvider({
  children
}: {
  children: ReactNode;
}) {
  return <SnackbarProvider maxSnack={3} anchorOrigin={{
    vertical: 'top',
    horizontal: 'right'
  }} data-sentry-element="SnackbarProvider" data-sentry-component="NotificationProvider" data-sentry-source-file="NotificationContext.tsx">
      <NotificationProviderInner data-sentry-element="NotificationProviderInner" data-sentry-source-file="NotificationContext.tsx">{children}</NotificationProviderInner>
    </SnackbarProvider>;
}
export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within NotificationProvider');
  }
  return context;
}