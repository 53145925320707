import { dataClient } from "@/components/ConfigureAmplify";
import { useCallback } from "react";

export type OrganizationType = 'NON_PROFIT' | 'COMMERCIAL' | 'EDUCATIONAL';

export interface CreateOrganizationInput {
  name: string;
  publicEmail?: string;
  type: OrganizationType;
  avatar?: string;
  details?: string;
  website?: string;
}

export interface Organization extends CreateOrganizationInput {
  id: string;
}

export interface UpdateOrganizationInput extends Partial<CreateOrganizationInput> {
  id: string;
}

export function useOrganization() {
  const createOrganization = useCallback(async (input: CreateOrganizationInput) => {
    try {
      const result = await dataClient.models.Organization.create({
        ...input,
        avatar: input.avatar || undefined
      });

      // Ensure we return a properly structured response with all fields
      return { 
        success: true, 
        data: {
          data: {
            id: result.data?.id || '',
            name: result.data?.name || '',
            type: result.data?.type || 'NON_PROFIT',
            avatar: result.data?.avatar || '',
            details: result.data?.details || '',
            website: result.data?.website || '',
            publicEmail: result.data?.publicEmail || '',
          }
        }
      };
    } catch (error) {
      console.error('Error creating organization:', error);
      return { success: false, error };
    }
  }, []);

  const getOrganization = useCallback(async (id: string) => {
    try {
      const result = await dataClient.models.Organization.get({ id });
      if (!result) {
        throw new Error('Organization not found');
      }
      // Ensure we return an object with all required fields
      return { 
        success: true, 
        data: {
          id: result.data?.id || '',
          name: result.data?.name || '',
          publicEmail: result.data?.publicEmail || '',
          details: result.data?.details || '',
          website: result.data?.website || '',
          type: result.data?.type || 'NON_PROFIT',
          avatar: result.data?.avatar || '',
        } 
      };
    } catch (error) {
      console.error('Error fetching organization:', error);
      return { success: false, error };
    }
  }, []);

  const listOrganizations = useCallback(async () => {
    try {
      const result = await dataClient.models.Organization.list();
      const organizations = result.data?.map(org => ({
        id: org.id,
        name: org.name || '',
        type: org.type || 'NON_PROFIT',
        avatar: org.avatar || '',
        details: org.details || '',
        website: org.website || '',
        publicEmail: org.publicEmail || '',
      })) || [];
      return { success: true, data: organizations };
    } catch (error) {
      console.error('Error listing organizations:', error);
      return { success: false, error };
    }
  }, []);

  const updateOrganization = useCallback(async (input: UpdateOrganizationInput) => {
    try {
      const result = await dataClient.models.Organization.update(input);
      return { success: true, data: result };
    } catch (error) {
      console.error('Error updating organization:', error);
      return { success: false, error };
    }
  }, []);

  return {
    createOrganization,
    getOrganization,
    listOrganizations,
    updateOrganization,
  };
}
