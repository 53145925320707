'use client';

import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Divider, Typography, Avatar, ListSubheader, IconButton, Tooltip } from '@mui/material';
import Image from 'next/image';
import { Science as ScienceIcon, Settings as SettingsIcon, Home as HomeIcon, Assignment as AssignmentIcon, Analytics as AnalyticsIcon, Inventory as InventoryIcon, ChevronLeft as ChevronLeftIcon, Menu as MenuIcon, BiotechOutlined as BiotechIcon, Business as BusinessIcon, Search as SearchIcon } from '@mui/icons-material';
import { getCurrentUser } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Logout from '../Logout';
import { getAvatarUrl, getUserDisplayName } from '@/utils/user-display';
import { dataClient } from '@/components/ConfigureAmplify';
import { getStoredNavState, setStoredNavState } from '@/utils/storage';
const drawerWidth = 240;
const collapsedWidth = 72;
const menuItems = [{
  section: 'Overview',
  items: [{
    text: 'Home',
    icon: <HomeIcon />,
    path: '/dashboard'
  }, {
    text: 'Organizations',
    icon: <BusinessIcon />,
    path: '/catalog/organizations'
  }, {
    text: 'Catalog',
    icon: <SearchIcon />,
    path: '/catalog'
  }, {
    text: 'Genera',
    icon: <SearchIcon />,
    path: '/catalog/genus'
  }, {
    text: 'Analytics',
    icon: <AnalyticsIcon />,
    path: '/analytics'
  }]
}, {
  section: 'Cultivation',
  items: [{
    text: 'Grow Room',
    icon: <ScienceIcon />,
    path: '/grow-room'
  }, {
    text: 'Lab Work',
    icon: <BiotechIcon />,
    path: '/lab'
  }, {
    text: 'Tasks',
    icon: <AssignmentIcon />,
    path: '/tasks'
  }, {
    text: 'Inventory',
    icon: <InventoryIcon />,
    path: '/inventory'
  }]
}, {
  section: 'Account',
  items: [{
    text: 'Settings',
    icon: <SettingsIcon />,
    path: '/settings'
  }]
}];
export default function LeftNav() {
  const pathname = usePathname();
  // Initialize with false and update after mount
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userInfo, setUserInfo] = useState<{
    username: string;
    email?: string;
    displayName?: string;
    avatar?: string;
  } | null>(null);

  // Move localStorage interaction to useEffect to avoid hydration mismatch
  useEffect(() => {
    setIsCollapsed(getStoredNavState());
  }, []);
  const handleCollapse = (collapsed: boolean) => {
    setIsCollapsed(collapsed);
    setStoredNavState(collapsed);
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {
          username,
          userId,
          signInDetails
        } = await getCurrentUser();
        const {
          data: userData
        } = await dataClient.models.User.get({
          id: userId
        });
        setUserInfo({
          username,
          email: signInDetails?.loginId,
          displayName: userData?.displayName ?? undefined,
          avatar: userData?.avatar ?? undefined
        });
      } catch (error) {
        console.error('Error fetching user:', error);
        setUserInfo(null);
      }
    };
    fetchUser();
  }, []);
  return <Drawer variant="permanent" sx={{
    width: isCollapsed ? collapsedWidth : drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: isCollapsed ? collapsedWidth : drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: 'background.paper',
      borderRight: '1px solid rgba(255,255,255,0.1)',
      display: 'flex',
      flexDirection: 'column',
      transition: theme => theme.transitions.create(['width'], {
        duration: theme.transitions.duration.shorter
      }),
      overflowX: 'hidden'
    }
  }} data-sentry-element="Drawer" data-sentry-component="LeftNav" data-sentry-source-file="LeftNav.tsx">
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pt: 2,
      pb: 1,
      minHeight: '80px'
    }} data-sentry-element="Box" data-sentry-source-file="LeftNav.tsx">
        <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: isCollapsed ? 'center' : 'space-between',
        alignItems: 'center',
        px: 2,
        mb: isCollapsed ? 1 : 0
      }} data-sentry-element="Box" data-sentry-source-file="LeftNav.tsx">
          <Image src="/images/logo.png" alt="WeMush Logo" width={isCollapsed ? 40 : 180} height={isCollapsed ? 40 : 54} style={{
          width: 'auto',
          height: isCollapsed ? '40px' : '45px',
          objectFit: 'contain'
        }} priority data-sentry-element="Image" data-sentry-source-file="LeftNav.tsx" />
          {!isCollapsed && <IconButton onClick={() => handleCollapse(true)} sx={{
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }
        }}>
              <ChevronLeftIcon />
            </IconButton>}
        </Box>
        {isCollapsed && <IconButton onClick={() => handleCollapse(false)} sx={{
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.05)'
        }
      }}>
            <MenuIcon />
          </IconButton>}
      </Box>
      
      {userInfo && <Box sx={{
      p: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: isCollapsed ? 'center' : 'flex-start',
      gap: 2
    }}>
          <Avatar src={userInfo?.avatar ? getAvatarUrl(userInfo.avatar) : undefined} sx={{
        bgcolor: 'primary.main'
      }}>
            {!userInfo?.avatar && getUserDisplayName(userInfo?.displayName, userInfo?.username, userInfo?.email)?.[0].toUpperCase()}
          </Avatar>
          {!isCollapsed && userInfo && <Box sx={{
        overflow: 'hidden'
      }}>
              <Typography variant="subtitle2" sx={{
          color: 'text.primary',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
                {getUserDisplayName(userInfo.displayName, userInfo.username, userInfo.email)}
              </Typography>
              <Typography variant="caption" sx={{
          color: 'text.secondary',
          display: 'block'
        }}>
                {userInfo.email}
              </Typography>
            </Box>}
        </Box>}

      <Divider sx={{
      borderColor: 'rgba(255,255,255,0.1)'
    }} data-sentry-element="Divider" data-sentry-source-file="LeftNav.tsx" />
      
      <List sx={{
      flexGrow: 1,
      '& .MuiListSubheader-root': {
        backgroundColor: 'transparent',
        color: 'text.secondary',
        fontSize: '0.75rem',
        lineHeight: '2.5em',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        pl: isCollapsed ? 0 : 2,
        textAlign: isCollapsed ? 'center' : 'left'
      }
    }} data-sentry-element="List" data-sentry-source-file="LeftNav.tsx">
        {menuItems.map(section => <Box key={section.section}>
            {!isCollapsed && <ListSubheader>{section.section}</ListSubheader>}
            {section.items.map(item => <ListItem key={item.text} disablePadding>
                <Tooltip title={isCollapsed ? item.text : ''} placement="right" arrow>
                  <ListItemButton component={Link} href={item.path} selected={pathname === item.path} sx={{
              py: 1.5,
              minHeight: 48,
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              px: isCollapsed ? 2.5 : 3,
              '&.Mui-selected': {
                backgroundColor: 'rgba(51, 204, 153, 0.1)',
                borderRight: '3px solid',
                borderColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'rgba(51, 204, 153, 0.2)'
                }
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.05)'
              }
            }}>
                    <ListItemIcon sx={{
                color: pathname === item.path ? 'primary.main' : 'text.secondary',
                minWidth: isCollapsed ? 0 : 40,
                mr: isCollapsed ? 0 : 2,
                justifyContent: 'center'
              }}>
                      {item.icon}
                    </ListItemIcon>
                    {!isCollapsed && <ListItemText primary={item.text} primaryTypographyProps={{
                sx: {
                  color: pathname === item.path ? 'primary.main' : 'text.primary',
                  fontWeight: pathname === item.path ? 500 : 400
                }
              }} />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>)}
            {section.section !== 'Account' && !isCollapsed && <Divider sx={{
          borderColor: 'rgba(255,255,255,0.1)',
          my: 1
        }} />}
          </Box>)}
      </List>

      <Divider sx={{
      borderColor: 'rgba(255,255,255,0.1)'
    }} data-sentry-element="Divider" data-sentry-source-file="LeftNav.tsx" />
      <Box sx={{
      p: isCollapsed ? 1 : 2
    }} data-sentry-element="Box" data-sentry-source-file="LeftNav.tsx">
        <Logout isCollapsed={isCollapsed} data-sentry-element="Logout" data-sentry-source-file="LeftNav.tsx" />
      </Box>
    </Drawer>;
}