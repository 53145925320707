'use client';

import { Box, TextField, InputAdornment, Link as MuiLink } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { useDebounce } from '@/hooks/useDebounce';
import { useState } from 'react';
import Link from 'next/link';
import LeftNav from './LeftNav';
export default function DashboardLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce((query: string) => {
    if (query.length >= 2) {
      router.push(`/catalog?q=${encodeURIComponent(query)}`);
    }
  }, 300);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };
  return <Box sx={{
    display: 'flex',
    minHeight: '100vh'
  }} data-sentry-element="Box" data-sentry-component="DashboardLayout" data-sentry-source-file="DashboardLayout.tsx">
      <LeftNav data-sentry-element="LeftNav" data-sentry-source-file="DashboardLayout.tsx" />
      <Box component="main" sx={{
      flexGrow: 1,
      minHeight: '100vh',
      backgroundColor: 'background.default',
      display: 'flex',
      flexDirection: 'column'
    }} data-sentry-element="Box" data-sentry-source-file="DashboardLayout.tsx">
        <Box sx={{
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper'
      }} data-sentry-element="Box" data-sentry-source-file="DashboardLayout.tsx">
          <TextField placeholder="Search catalog..." value={searchQuery} onChange={handleSearchChange} size="small" sx={{
          width: 300
        }} InputProps={{
          startAdornment: <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
        }} data-sentry-element="TextField" data-sentry-source-file="DashboardLayout.tsx" />
        </Box>
        <Box sx={{
        flexGrow: 1,
        p: 3
      }} data-sentry-element="Box" data-sentry-source-file="DashboardLayout.tsx">
          {children}
        </Box>
        <Box component="footer" sx={{
        p: 2,
        borderTop: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
        display: 'flex',
        justifyContent: 'center',
        gap: 2
      }} data-sentry-element="Box" data-sentry-source-file="DashboardLayout.tsx">
          <MuiLink component={Link} href="/terms" color="text.secondary" data-sentry-element="MuiLink" data-sentry-source-file="DashboardLayout.tsx">
            Terms of Service
          </MuiLink>
          <MuiLink component={Link} href="/privacy" color="text.secondary" data-sentry-element="MuiLink" data-sentry-source-file="DashboardLayout.tsx">
            Privacy Policy
          </MuiLink>
        </Box>
      </Box>
    </Box>;
}