'use client';

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import styles from './GenusForm.module.css';
import { Box, Button, TextField, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Stack, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { DeleteOutline as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { uploadFile, StoragePaths } from '@/utils/storage';
import { isValidFile } from '@/utils/security';
import type { Genus } from '@/models/types';
import SignedImage from '@/components/image/SignedImage';
interface GenusFormProps {
  onSubmit: (data: Partial<Genus>) => Promise<void>;
  isSubmitting: boolean;
  initialData?: Genus;
}
export default function GenusForm({
  onSubmit,
  isSubmitting,
  initialData
}: GenusFormProps) {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {
      errors
    }
  } = useForm<Partial<Genus>>({
    defaultValues: initialData || {
      name: '',
      description: '',
      commonNames: [],
      habitat: '',
      edible: false,
      medicinal: false,
      ecologicalRole: 'UNKNOWN',
      images: []
    }
  });
  const images = watch('images') || [];
  const handleCommonNamesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const names = event.target.value.split(',').map(name => name.trim()).filter(Boolean);
    setValue('commonNames', names);
  };
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) return;
    try {
      const file = files[0];
      if (!isValidFile(file)) {
        throw new Error('Invalid file type');
      }
      const fileName = `${Date.now()}-${file.name.replace(/[^a-zA-Z0-9.-]/g, '')}`;
      const path = StoragePaths.uploadImage(fileName);
      await uploadFile(file, path, {
        onProgress: setUploadProgress,
        validateFn: isValidFile
      });
      const currentImages = watch('images') || [];
      setValue('images', [...currentImages, path], {
        shouldDirty: true
      });
      setUploadProgress(0);
      event.target.value = ''; // Reset the input
    } catch (error) {
      console.error('Upload failed:', error);
      // Show error notification
    }
  };
  const handleRemoveImage = (indexToRemove: number) => {
    setValue('images', images.filter((_, index) => index !== indexToRemove));
  };
  return <Box component="form" onSubmit={handleSubmit(onSubmit)} data-sentry-element="Box" data-sentry-component="GenusForm" data-sentry-source-file="GenusForm.tsx">
      <Stack spacing={3} data-sentry-element="Stack" data-sentry-source-file="GenusForm.tsx">
        <TextField {...register('name', {
        required: 'Name is required'
      })} label="Name" required fullWidth error={!!errors.name} helperText={errors.name?.message} data-sentry-element="TextField" data-sentry-source-file="GenusForm.tsx" />

        <TextField {...register('description')} label="Description" multiline rows={4} fullWidth data-sentry-element="TextField" data-sentry-source-file="GenusForm.tsx" />

        <TextField label="Common Names" helperText="Enter names separated by commas" fullWidth defaultValue={initialData?.commonNames?.join(', ')} onChange={handleCommonNamesChange} data-sentry-element="TextField" data-sentry-source-file="GenusForm.tsx" />

        <TextField {...register('habitat')} label="Habitat" fullWidth data-sentry-element="TextField" data-sentry-source-file="GenusForm.tsx" />

        <FormControl fullWidth data-sentry-element="FormControl" data-sentry-source-file="GenusForm.tsx">
          <FormLabel data-sentry-element="FormLabel" data-sentry-source-file="GenusForm.tsx">Ecological Role</FormLabel>
          <Select {...register('ecologicalRole')} defaultValue={initialData?.ecologicalRole || 'UNKNOWN'} data-sentry-element="Select" data-sentry-source-file="GenusForm.tsx">
            <MenuItem value="SAPROBIC" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Saprobic</MenuItem>
            <MenuItem value="PARASITIC" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Parasitic</MenuItem>
            <MenuItem value="SYMBIOTIC" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Symbiotic</MenuItem>
            <MenuItem value="MYCORRHIZAL" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Mycorrhizal</MenuItem>
            <MenuItem value="ENDOPHYTIC" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Endophytic</MenuItem>
            <MenuItem value="LICHENIZED" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Lichenized</MenuItem>
            <MenuItem value="FACULTATIVE" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Facultative</MenuItem>
            <MenuItem value="UNKNOWN" data-sentry-element="MenuItem" data-sentry-source-file="GenusForm.tsx">Unknown</MenuItem>
          </Select>
        </FormControl>

        <FormGroup data-sentry-element="FormGroup" data-sentry-source-file="GenusForm.tsx">
          <FormControlLabel control={<Checkbox {...register('edible')} defaultChecked={initialData?.edible || false} />} label="Edible" data-sentry-element="FormControlLabel" data-sentry-source-file="GenusForm.tsx" />
          <FormControlLabel control={<Checkbox {...register('medicinal')} defaultChecked={initialData?.medicinal || false} />} label="Medicinal" data-sentry-element="FormControlLabel" data-sentry-source-file="GenusForm.tsx" />
        </FormGroup>

        {/* Image Upload Section */}
        <Box data-sentry-element="Box" data-sentry-source-file="GenusForm.tsx">
          <Typography variant="subtitle1" gutterBottom data-sentry-element="Typography" data-sentry-source-file="GenusForm.tsx">Images</Typography>
          <input type="file" accept="image/*" className={styles['hiddenInput']} id="image-upload" onChange={handleImageUpload} disabled={isSubmitting} />
          <label htmlFor="image-upload">
            <Button component="span" variant="outlined" startIcon={<AddIcon />} disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="GenusForm.tsx">
              Add Image
            </Button>
          </label>
          
          {uploadProgress > 0 && <Box sx={{
          mt: 1
        }}>
              <Typography variant="body2">Uploading: {uploadProgress}%</Typography>
            </Box>}

          {images.length > 0 && <ImageList sx={{
          mt: 2
        }} cols={3} rowHeight={164}>
              {images.map((image, index) => <ImageListItem key={index}>
                  {image && <SignedImage storagePath={image} alt={`Genus image ${index + 1}`} fill style={{
              objectFit: 'cover'
            }} />}
                  <ImageListItemBar actionIcon={<IconButton sx={{
              color: 'rgba(255, 255, 255, 0.54)'
            }} onClick={() => handleRemoveImage(index)}>
                        <DeleteIcon />
                      </IconButton>} />
                </ImageListItem>)}
            </ImageList>}
        </Box>

        <Box sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'flex-end'
      }} data-sentry-element="Box" data-sentry-source-file="GenusForm.tsx">
          <Button type="submit" variant="contained" disabled={isSubmitting} data-sentry-element="Button" data-sentry-source-file="GenusForm.tsx">
            {isSubmitting ? 'Saving...' : initialData ? 'Update' : 'Create'} Genus
          </Button>
        </Box>
      </Stack>
    </Box>;
}