// components/ConfigureAmplify.tsx
"use client";

import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/data";
import outputs from "@/amplify_outputs.json";
import type { Schema } from "@/amplify/data/resource";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Amplify.configure(outputs as any, {
  ssr: true
});
export default function ConfigureAmplifyClientSide() {
  return null;
}
export const dataClient = generateClient<Schema>();