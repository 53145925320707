const ALLOWED_FILE_EXTENSIONS = [
  // Images
  '.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg',
  // Documents
  '.pdf', '.doc', '.docx', '.txt',
  // Data
  '.csv', '.json',
] as const;

type AllowedExtension = typeof ALLOWED_FILE_EXTENSIONS[number];

const ALLOWED_MIME_TYPES = [
  // Images
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  // Documents
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  // Data
  'text/csv',
  'application/json',
] as const;

type AllowedMimeType = typeof ALLOWED_MIME_TYPES[number];

/**
 * Generates a secure filename by:
 * 1. Removing special characters
 * 2. Converting to lowercase
 * 3. Replacing spaces with hyphens
 * 4. Preserving the original extension if it's allowed
 * 5. Adding a timestamp for uniqueness
 * 
 * @param originalFilename The original filename to sanitize
 * @returns A sanitized and unique filename
 */
export function generateSecureFilename(originalFilename: string): string {
  // Extract the extension
  const lastDot = originalFilename.lastIndexOf('.');
  const ext = lastDot > -1 ? originalFilename.slice(lastDot).toLowerCase() : '';
  const nameWithoutExt = lastDot > -1 ? originalFilename.slice(0, lastDot) : originalFilename;

  // Validate extension
  if (!ALLOWED_FILE_EXTENSIONS.includes(ext as AllowedExtension)) {
    throw new Error(`File extension ${ext} is not allowed`);
  }

  // Sanitize the filename:
  // 1. Convert to lowercase
  // 2. Remove special characters except hyphens and underscores
  // 3. Replace spaces with hyphens
  // 4. Remove multiple consecutive hyphens
  const sanitizedName = nameWithoutExt
    .toLowerCase()
    .replace(/[^a-z0-9-_\s]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  // Add timestamp for uniqueness
  const timestamp = Date.now();
  
  return `${sanitizedName}-${timestamp}${ext}`;
}

/**
 * Validates if a MIME type is allowed
 * @param mimeType The MIME type to validate
 * @returns boolean indicating if the MIME type is allowed
 */
export function isAllowedMimeType(mimeType: string): boolean {
  return ALLOWED_MIME_TYPES.includes(mimeType as AllowedMimeType);
}

/**
 * Validates if a file extension is allowed
 * @param filename The filename to validate
 * @returns boolean indicating if the file extension is allowed
 */
export function isAllowedFileExtension(filename: string): boolean {
  const ext = filename.slice(filename.lastIndexOf('.')).toLowerCase();
  return ALLOWED_FILE_EXTENSIONS.includes(ext as AllowedExtension);
}

/**
 * Validates both MIME type and file extension
 * @param file The File object to validate
 * @returns boolean indicating if the file is valid
 */
export function isValidFile(file: File): boolean {
  return isAllowedMimeType(file.type) && isAllowedFileExtension(file.name);
}
