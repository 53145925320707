"use client";

import { Button, IconButton, Tooltip } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { signOut } from "aws-amplify/auth";
import { useRouter } from "next/navigation";
interface LogoutProps {
  isCollapsed?: boolean;
}
export default function Logout({
  isCollapsed
}: LogoutProps) {
  const router = useRouter();
  const handleSignOut = async () => {
    await signOut();
    router.push("/login");
  };
  if (isCollapsed) {
    return <Tooltip title="Sign out" placement="right" arrow>
        <IconButton onClick={handleSignOut} sx={{
        color: '#4CAF50',
        '&:hover': {
          backgroundColor: 'rgba(76, 175, 80, 0.1)'
        }
      }}>
          <LogoutIcon />
        </IconButton>
      </Tooltip>;
  }
  return <Button variant="outlined" startIcon={<LogoutIcon />} onClick={handleSignOut} fullWidth sx={{
    py: 1,
    color: '#4CAF50',
    borderColor: '#4CAF50',
    '&:hover': {
      borderColor: '#388E3C',
      backgroundColor: 'rgba(76, 175, 80, 0.1)'
    }
  }} data-sentry-element="Button" data-sentry-component="Logout" data-sentry-source-file="Logout.tsx">
      Sign out
    </Button>;
}