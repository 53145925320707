'use client';

import { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Chip, Typography, Box } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { dataClient } from '@/components/ConfigureAmplify';
import type { WorkflowInstance, WorkflowTemplate } from '@/models/types';
interface WorkflowWithTemplate extends WorkflowInstance {
  template?: WorkflowTemplate | null;
}
export default function WorkflowList() {
  const [workflows, setWorkflows] = useState<WorkflowWithTemplate[]>([]);
  useEffect(() => {
    loadWorkflows();
  }, []);
  async function loadWorkflows() {
    try {
      const {
        data
      } = await dataClient.models.WorkflowInstance.list({
        filter: {
          status: {
            eq: 'ACTIVE'
          }
        }
      });
      const workflowsWithTemplates = await Promise.all(data.map(async workflow => {
        const templateResponse = await dataClient.models.WorkflowTemplate.get({
          id: workflow.workflowTemplateId
        });
        return {
          ...workflow,
          template: templateResponse?.data || null
        };
      }));
      setWorkflows(workflowsWithTemplates);
    } catch (error) {
      console.error('Error loading workflows:', error);
    }
  }
  if (workflows.length === 0) {
    return <Typography color="text.secondary" align="center">
        No active workflows found
      </Typography>;
  }
  return <List data-sentry-element="List" data-sentry-component="WorkflowList" data-sentry-source-file="WorkflowList.tsx">
      {workflows.map(workflow => <ListItem key={workflow.id} divider sx={{
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.05)'
      }
    }}>
          <ListItemText primary={<Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
                {workflow.productType}
                <Chip label={workflow.template?.name || 'Unknown Template'} size="small" color="primary" variant="outlined" />
              </Box>} secondary={<>
                <Typography component="span" variant="caption" color="text.secondary" sx={{
          mr: 1
        }}>
                  {workflow.template?.type || 'Unknown Type'}
                </Typography>
                Started: {new Date(workflow.startedAt).toLocaleDateString()}
              </>} />
          <Chip label={`Step ${workflow.currentStepIndex + 1}`} color="primary" size="small" sx={{
        mr: 2
      }} />
          <ListItemSecondaryAction>
            <IconButton edge="end" href={`/lab/workflow/${workflow.id}`}>
              <QrCodeIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>)}
    </List>;
}