import { uploadData, getUrl, remove } from 'aws-amplify/storage';
import { generateSecureFilename } from './security';
import { dataClient } from '@/components/ConfigureAmplify';

export const StoragePaths = {
  organizationAvatar: (entityId: string, fileName: string) => 
    `orgs/avatars/${entityId}/${fileName}`,
  
  uploadImage: (fileName: string) => 
    `uploads/images/${fileName}`,
  
  uploadAvatar: (fileName: string) => 
    `uploads/avatars/${fileName}`,
} as const;

export type StoragePathKey = keyof typeof StoragePaths;

// Local storage keys
const STORAGE_KEYS = {
  NAV_COLLAPSED: 'nav_collapsed_state'
} as const;

// Define storage paths matching backend configuration
export const STORAGE_PATHS = {
  ORGANIZATIONS: {
    AVATARS: (orgId: string, filename: string) => {
      const salt = crypto.randomUUID().slice(0, 8);
      const safeName = generateSecureFilename(filename);
      return `orgs/avatars/${orgId}/${salt}_${safeName}`;
    },
    ORIGINAL_AVATARS: (orgId: string, filename: string) => {
      const salt = crypto.randomUUID().slice(0, 8);
      const safeName = generateSecureFilename(filename);
      return `orgs/avatars/${orgId}/original/${salt}_${safeName}`;
    }
  },
  UPLOADS: {
    IMAGES: (fileName: string) => 
      `uploads/images/${fileName}`,
    AVATARS: (fileName: string) => 
      `uploads/avatars/${fileName}`,
    STRAINS: (fileName: string) =>
      `uploads/strains/${fileName}`,
  }
} as const;

export const AVATAR_SIZES = {
  THUMBNAIL: 150,
  MEDIUM: 300,
  LARGE: 600
} as const;

export const getStoredNavState = (): boolean => {
  if (typeof window === 'undefined') return false;
  try {
    return localStorage.getItem(STORAGE_KEYS.NAV_COLLAPSED) === 'true';
  } catch (error) {
    console.debug(`Failed to retrieve nav state - error: ${error}`);
    return false;
  }
};

export const setStoredNavState = (collapsed: boolean): void => {
  if (typeof window === 'undefined') return;
  try {
    localStorage.setItem(STORAGE_KEYS.NAV_COLLAPSED, String(collapsed));
  } catch (e) {
    console.warn('Failed to store nav state:', e);
  }
};

export type UploadResult = {
  path: string;
  url: string;
};

export interface ImageSizes {
  original: number;
  thumbnail?: number;
  medium?: number;
  large?: number;
}

export interface UploadOptions {
  maxSize?: number;
  validateFn?: (file: File) => boolean | Promise<boolean>;
  onProgress?: (progress: number) => void;
}

export async function getSignedUrl(path: string): Promise<string> {
  try {
    const { url } = await getUrl({ 
      path,
      options: {
        expiresIn: 3600 * 24 * 7 // 7 days
      }
    });
    return url.toString();
  } catch (error) {
    console.error('Error getting signed URL:', error);
    throw error;
  }
}

export async function uploadFile(
  file: File,
  path: string,
  options?: UploadOptions
): Promise<UploadResult> {
  const maxSize = options?.maxSize || 5 * 1024 * 1024;
  
  if (file.size > maxSize) {
    throw new Error(`File size exceeds ${maxSize / (1024 * 1024)}MB limit`);
  }

  if (options?.validateFn) {
    const isValid = await options.validateFn(file);
    if (!isValid) {
      throw new Error('File validation failed');
    }
  }

  try {
    const uploadResult = await uploadData({
      path,
      data: file,
      options: {
        contentType: file.type,
        contentDisposition: 'inline',
        onProgress: (progress) => {
          options?.onProgress?.(progress.transferredBytes / (progress.totalBytes || 1) * 100);
        },
      },
    }).result;

    if (!uploadResult?.path) {
      throw new Error('Upload failed');
    }

    // Get initial signed URL
    const signedUrl = await getSignedUrl(uploadResult.path);

    // Create the Image record with permanent data
    const datetimeNow = new Date().toISOString();
    await dataClient.models.Image.create({
      filename: file.name,
      contentType: file.type,
      key: uploadResult.path,
      url: signedUrl,
      status: 'UPLOADED',
      createdAt: datetimeNow,
      updatedAt: datetimeNow,
      uploadedBy: 'system',
      uploadedAt: datetimeNow,
      urlExpiresAt: new Date(Date.now() + 3600 * 24 * 7 * 1000).toISOString() // 7 days from now
    });

    return {
      path: uploadResult.path,
      url: signedUrl
    };
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export async function deleteFile(path: string): Promise<void> {
  try {
    await remove({ path });
    await dataClient.models.Image.list({ filter: { key: { eq: path } } }).then((response) => {
      if (response.data && response.data.length > 0) {
        const imageId = response.data[0].id;
        const datetimeNow = new Date().toISOString();
        dataClient.models.Image.update({ id: imageId, status: 'DELETED', deletedAt: datetimeNow, updatedAt: datetimeNow });
      }
    });
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
}
