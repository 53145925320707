import type { VariantType } from 'notistack';
import { useNotification } from '@/contexts/NotificationContext';

export function useNotifications() {
  const { showNotification } = useNotification();

  return {
    showNotification: (message: string, options?: { 
      variant?: VariantType;
      autoHideDuration?: number;
      preventDuplicate?: boolean;
    }) => {
      showNotification(message, options?.variant);
    },
    showSuccessNotification: (message: string) => {
      showNotification(message, 'success');
    }
  };
}
