'use client';

import { Avatar } from '@mui/material';
import type { AvatarProps } from '@mui/material';
import { Business as BusinessIcon } from '@mui/icons-material';
interface OrganizationAvatarProps extends Omit<AvatarProps, 'src'> {
  avatarUrl?: string | null;
}
export default function OrganizationAvatar({
  avatarUrl,
  sx,
  ...props
}: OrganizationAvatarProps) {
  return <Avatar src={avatarUrl || undefined} sx={{
    bgcolor: 'grey.200',
    ...sx
  }} {...props} data-sentry-element="Avatar" data-sentry-component="OrganizationAvatar" data-sentry-source-file="OrganizationAvatar.tsx">
      <BusinessIcon data-sentry-element="BusinessIcon" data-sentry-source-file="OrganizationAvatar.tsx" />
    </Avatar>;
}